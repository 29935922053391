import { template as template_e9cd299fa138410badab76d0bd7a7be4 } from "@ember/template-compiler";
const FKText = template_e9cd299fa138410badab76d0bd7a7be4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
