import { template as template_5ec72a84a5fb4c2fa880de157a0145e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_5ec72a84a5fb4c2fa880de157a0145e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
