import { template as template_1f5c27cca1ba4a2fb95da8481d00da0d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_1f5c27cca1ba4a2fb95da8481d00da0d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
